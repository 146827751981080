<template>
  <div class="content-amounts-all">
    <!-- #region Content amount MXN -->
    <div class="content-amounts" :class="sClassDynamicAmountContent">
      <div
        v-for="(item, i) in aAmounts"
        :key="i"
        class="content-amount-card"
        :class="sClassDynamicAmountCard"
      >
        <div class="content-text">
          <span class="info-text">
            <!-- Órdenes de compra finalizadas -->
            {{ item.sTitle }}
          </span>
        </div>
        <div class="content-icon-number">
          <div class="content-btn">
            <v-btn depressed class="btn-icon-pound" :class="item.sColorBtn">
              <span class="material-icons icon-pound-dash"> attach_money </span>
            </v-btn>
          </div>
          <div class="content-number">
            <span class="number-text" :class="item.sColorText">
              ${{ formatMoneyGlobal(item.iAmount).replace("-", "") }} MXN
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion Content amount MXN -->

    <!-- #region Content amount USD -->
    <div class="content-amounts-usd" :class="sClassDynamicAmountContent">
      <div
        v-for="(item, i) in aAmountsUSD"
        :key="i"
        class="content-amount-card"
        :class="sClassDynamicAmountCard"
      >
        <div class="content-text">
          <span class="info-text">
            <!-- Órdenes de compra finalizadas -->
            {{ item.sTitle }}
          </span>
        </div>
        <div class="content-icon-number">
          <div class="content-btn">
            <v-btn depressed class="btn-icon-pound" :class="item.sColorBtn">
              <span class="material-icons icon-pound-dash"> attach_money </span>
            </v-btn>
          </div>
          <div class="content-number">
            <span class="number-text" :class="item.sColorText">
              ${{ formatMoneyGlobal(item.iAmount).replace("-", "") }} USD
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion Content amount USD -->

  </div>
</template>

<script>
export default {
  props: {
    aAmounts: Array,
    aAmountsUSD: Array,
  },
  data() {
    return {
      screenWidth: 0,
      sClassDynamicAmountContent: "",
      sClassDynamicAmountCard: "",
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // Esta validacion esta ajustada a 3 cartas o contenidos
      if (window.innerWidth > 959.99 && window.innerWidth < 1240) {
        this.sClassDynamicAmountContent = "";
        this.sClassDynamicAmountCard = "";
        if (this.bSidebar) {
          // Clase dinamica activada con sidebar abierto
          this.sClassDynamicAmountContent =
            "dynamic-class-open-sidebar-content-amounts";
          this.sClassDynamicAmountCard =
            "dynamic-class-open-sidebar-content-amount-card";
        } else {
          if (window.innerWidth > 959.99 && window.innerWidth < 1020) {
            // Clase dinamica activada con sidebar cerrado
            this.sClassDynamicAmountContent =
              "dynamic-class-close-sidebar-content-amounts";
            this.sClassDynamicAmountCard =
              "dynamic-class-close-sidebar-content-amount-card";
          }
        }
      } else {
        // Clase dinamica desactivada
        this.sClassDynamicAmountContent = "";
        this.sClassDynamicAmountCard = "";
      }
    },
  },
  computed: {
    bSidebar() {
      return this.$store.state.bSidebar;
    },
  },
  watch: {
    bSidebar() {
      this.handleResize();
    },
  },
};
</script>

<style scoped>
.dynamic-class-open-sidebar-content-amounts {
  display: block !important;
}

.dynamic-class-open-sidebar-content-amount-card {
  margin-bottom: 15px !important;
}

.dynamic-class-close-sidebar-content-amounts {
  display: flex !important;
}

.dynamic-class-close-sidebar-content-amount-card {
  margin-bottom: 0px !important;
}

.dynamic-class-close-sidebar-content-amount-card
  .content-icon-number
  .content-number
  .number-text {
  font-size: 14px !important;
}

.content-amounts {
  display: flex;
}

.content-amounts-usd {
  display: flex;
  margin-top: 20px;
}


.content-amount-card {
  width: 100%;
  height: 85px;
  margin-right: 10px;
  padding: 10px 20px 10px 20px;
  background-color: var(--primary-color-background-table) !important;
  border: 1px solid var(--primary-color-border-input);
  border-radius: 10px;
  align-items: center;
}

.content-icon-number {
  display: flex;
  align-items: center;
  place-content: start;
}

.content-number {
  margin-left: 10px;
}

.btn-currency-color-yellow {
  background-color: var(--primary-color-text-yellow) !important;
}

.btn-currency-color-green {
  background-color: var(--primary-color-text-green) !important;
}

.btn-currency-color-red {
  background-color: var(--primary-color-text-red) !important;
}

.text-currency-color-yellow {
  color: var(--primary-color-text-yellow) !important;
}

.text-currency-color-green {
  color: var(--primary-color-text-green) !important;
}

.text-currency-color-red {
  color: var(--primary-color-text-red) !important;
}

.btn-icon-pound {
  /* background-color: var(--primary-color-background-icon-status-blue) !important; */
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;
  border-radius: 100%;
}

.icon-pound-dash,
.btn-icon-currency {
  color: var(--primary-color-text-white) !important;
}

.btn-icon-currency {
  background-color: var(
    --primary-color-background-icon-status-green
  ) !important;
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;
  border-radius: 100%;
}

.number-text {
  font-family: "pop-Bold";
  font-size: 16px;
  color: var(--primary-color-text);
}

.info-text {
  font-family: "pop-Regular";
  font-size: 12px;
  color: var(--primary-color-text);
}

/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-amounts {
    display: block;
    margin-right: 10px;
    margin-left: 10px;
  }
  .content-amounts-usd {
    display: block;
    margin-right: 10px;
    margin-left: 10px;
  }

  .content-amount-card {
    width: 100%;
    height: 85px;
    padding: 10px 20px 10px 20px;
    margin-bottom: 10px;
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    border-radius: 10px;
    align-items: center;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-amounts {
    display: block;
    margin-right: 10px;
    margin-left: 10px;
  }
  .content-amounts-usd {
    display: block;
    margin-right: 10px;
    margin-left: 10px;
  }

  .content-amount-card {
    width: 100%;
    height: 85px;
    padding: 10px 20px 10px 20px;
    margin-bottom: 10px;
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    border-radius: 10px;
    align-items: center;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>
